@import url('https://fonts.googleapis.com/css2?family=Happy+Monkey&family=Orbitron:wght@700&display=swap');

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: "Avenir";
  }

  .svg_icons {
    transform: scale(2.0);
  }
  
  body {
    background-color: white;
  }

h1 {
  padding-top: .75%; 
padding-left: 2%; 
}

#sloganText {
padding-left: 2%; 
  font-style: italic;
}

.website-heading{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  text-align: left;
  margin-left: 20px; 
}

.website-heading > h1{
font-size: 1.5rem; 
}

  p {
    line-height: 140%;
  }

  #navbox {
display: flex;
flex-flow: nowrap;
flex-direction: row;
justify-content: space-around;
background-color: #49a396;
padding-top: 10px; 
padding-bottom: 10px;
  }

  nav div {
    width: 20px;
    border-radius: 6px;
    margin: 10px; 
    list-style-type: none;
    flex-grow: 1;
    text-align: center;

  }

  a {
    text-decoration: none;
    color: white; 
    font-weight: 500;
  }

  a:visited {
    text-decoration: none;
    color: white; 
  }

  a.left
{
    position: relative;
}

a.left:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #FFF;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

a.left:hover:before {
  visibility: visible;
  width: 100%;
}

  #headline {
    margin-top: 20px; 
    width: 100%; 
    border-bottom: solid 10px rgba(201, 216, 200, 1);
    background: -webkit-linear-gradient(bottom left, rgba(255,255,255,1), rgba(201,216,200,0.25));
    color: black; 
    display: flex;
flex-flow: nowrap;
flex-direction: row;
justify-content: center;
position: relative;
box-sizing: border-box;
  }

  #cta-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    margin-bottom: 40px;
  }


  .theory-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .theory-info > h2 {
    font-family: "Avenir";
    color: black; 
    text-align: center;
    font-size: 2rem;
  }

  .theory-info > h3 {
    font-family: "Avenir";
    color: black; 
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
  }

  #heading-h3-theoryinfo{
    background-color: rgba(201, 216, 200, 0.5);
    padding: 5px 10px;
    color: black; 
    font-weight: 400;
    font-size: 1.15rem;
    margin-bottom: 20px;

  }


  h2 {
    margin-top: 40px; 
    margin-bottom: 20px;
  }

  #principle-box {
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    justify-content: space-around;
  }

  #principle-box div:hover {
    background-color: #845048;
  }

  #principle-box div {
    width: 400px;
    background-color: #49a396;
    color: white; 
    padding: 20px; 
  }
  
  .principle {
    box-shadow: 10px 10px 40px 5px rgb(158, 156, 156); 
    margin: 20px; 
  }

  h3 {
    text-align: center;
    padding-bottom: 15px;
    color: white; 
  }

  .header-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5%; 
    height: 100px;
    margin-top: 10px;
  }

  header img {
    float: left;
    margin-left: 2%;
    margin-right: 2%;
  
  }

  .material-symbols-outlined {
    font-family: 'Material Icons';
    font-weight: lighter;
    color: white; 
    display: inline-block;
    line-height: 1;
    width: 100%;
    text-align: center;
  }

  .principle-box p {
    background-color: #c9d8c8;
    padding: 10px; 
    border-radius: 5px;
  }


  #footer-box {
display: flex;
flex-flow: wrap;
flex-direction: row;
justify-content: space-around;
background-color: #c9d8c8;
margin-top: 40px;
  }

 #footer-links li {
    list-style-type: none;
    line-height: 150%;
  }

  .footer-info {
    border-top: 3px solid #9aa799;
    min-width: 300px;
    padding: 20px 0px 20px 50px;
    flex-grow: 1; 
  }

 #footer-links li {
    font-weight: 500;
  }

 #footer-links a {
    color: black;
    font-weight: 500;
  }

  #footer-links a:visited {
    color: black;
    font-weight: 500;
  }

  #footer-links a:hover{
    color: #36581f;
    font-weight: 500;
  }

table {
  width: 100% 
}

@media (max-width: 600px) {
  table {
    width: 300px;
    margin: 0 auto; 
  }
  footer li {
    text-align: center;
  }
  .footer-info {
    flex-grow: 1;
    align-items: stretch;
    border: none;
  }
  #footer-links {
    order: 1;
    border-top: 3px solid #9aa799;
  }
}

  table tr:nth-child(odd) {
    background-color: #9aa799;
    color: #FFF;
  }

  table td {
    padding: 5px 10px 5px 10px;
    border-radius: 6px;
    font-weight: 500;
  }

  .icon::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  #list-curricula{
    margin-top: 5%; 
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  #singleCurr{
    margin-top: 50px; 
    margin-left: 25px; 
    margin-right: 25px; 
    border-radius: 5px;
    background-color: rgba(201,216,200,0.15);
    padding: 20px; 
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }

  #singleCurr h2 {
    margin: 0px; 
    margin-bottom: 20px; 
  }

  #singleCurr button{
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px; 
    padding: 5px 10px; 
    background-color: #49a396;
    color: white; 
    border: none; 
    border-radius: 5px;
  }

  #singleCurr p{
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px; 
    padding: 5px 10px; 
    background-color: #d87709;
    color: white; 
    border: none; 
    border-radius: 5px;
  }

  #cirr-banner{
    position: relative;
    z-index: 0;
    margin: 30px 0px; 
    padding: 30px 0px; 
    background-image: url('/public/images/longshortImg.jpg');
    background-size: cover; 
    background-position: center center;
  
  }

  #cirr-banner h1{
    color: white; 
    padding-left: 0px; 
  }

  #cirr-banner-overlay {
    position: absolute;
    z-index: 10;
    background-image: linear-gradient(45deg, rgba(132, 80, 72, 1), rgba(132, 80, 72, 0.75), rgba(132, 80, 72, 0.5));
  width: 100%;
  height: 100%; 
  top: 0px; 

  }

  #cirr-banner-info{
    color: white; 
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-between;
    margin-left: 100px; 
    align-items: flex-start;
    z-index: 50;
  }

  .slide {
    position: relative;  
    transform: scale(0.8);
    transition: transform 300ms;
    opacity: 0.5;
    z-index: -1;
  }
  
  .activeSlide {
    position: relative;  
    transform: scale(1.1);
    align-items: center;
    opacity: 1;
    z-index: 10;
    overflow: visible;
  }

  .activeSlide img:hover {
    position: fixed;
    top: 0px; 
    transform: scale(2); 
    z-index: 100;
    border: 2px black solid; 
    padding: 0Trapx; 
  }

  #musictheory-page{
    max-width: 100vw;
  }

  #musictheory-page h1{
    padding-top: 40px; 
    padding-left: 40px; 
    padding-bottom: 20px; 
    text-align: start;
  }

  #musictheory-page p{
    padding-left: 40px;
    padding-right: 40px; 
    text-align: start;
  }

  .important-paragraph{
    font-size: 1.3rem;
    padding-bottom: 10px;
    line-height: 160%;
  }

  .span-bold{
    font-weight: 700;
    color:#36581f; 
  }

  .span-underline{
    text-decoration:#36581f underline; 
  }

  #listeningtracks-banner{
    position: relative;
    margin-top: 40px; 
    padding: 40px; 
    background-image: linear-gradient(180deg, rgba(54, 88, 31, .25), rgba(255, 255, 255, 0));
    display: flex;
    flex-direction: column;
  }

  #listeningtracks-banner a{
    margin-left: 40px; 
    max-width: fit-content;
    font-weight: 400;
    text-align: start;
    color: black; 
    line-height: 180%;
  }

  #listeningtracks-banner a:hover {
    text-decoration: underline;
   
  }

#musictheory-additional-par{
  font-size: 1.1rem;
  line-height: 150%;
  margin-bottom: 40px;
}

#musictheory-additional-par p{
  margin-top: 20px; 
}

#listeningtrack-banner-desktop{
  position: absolute; 
  top: 0; 
  right: 0; 
  z-index: -1;

}

#listeningtrack-banner-desktop h2{
  background-color: #36581f;
  color: white; 
  padding: 10px 20px 10px 350px; 

}

.slider-container{
  width: 100%;
  height: 500px;
  overflow: visible;
}

.slide-button{
  background-color: #49a396;
  color: #ffffff;  
  font-size: 1.2rem;
  border: none;
  border-radius: 10px;
  padding: 10px 50px; 
  margin: 20px; 
  cursor: pointer;
}

.slide-button:hover{
  border: 2px solid #c9d8c8;
  background-image: linear-gradient(to right, #49a396, rgba(255, 255, 255, 0.05));
}

#cta-book {
  background-image: linear-gradient(135deg, #49a396, #7da09b);
  padding-top: 10px; 
  padding-bottom: 10px;
  display: block;
  width: 80%; 
  margin-left: 10%; 
  color: white; 
  font-size: 1.35rem; 
  align-self: center;

}

#cta-book:hover {
  background-image: linear-gradient(135deg, #36581f, #72796e);
}


#singleCurrDownload {
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: start;
margin-left: 48px; 

}

#singleCurrDownload h3 {
  margin-top: 20px;
  color: black; 
}

#singleCurrDownload ol {
  text-align: left;
}

#singleCurrDownload li {
  line-height: 200%;
}


#singleCurrDownload p{
  margin-bottom: 20px;
}


#singleCurrDownload a{
  margin-top: 20px;
  background-color: #36581f;
  padding: 10px 20px; 
  border-radius: 10px;
  margin-bottom: 20px; 

}

#about-amy{
  text-align: left;
}

#about-amy h1{
  margin: 2% 1%; 
}

#about-amy img{
  border-radius: 200px;
  object-fit: contain;
  margin: 0 2%; 
}

#about-amy p {
  margin: 0 2%; 
  padding: 1%; 
  line-height: 160%;
}

#about-amy a {
  color:#36581f;
  font-weight: 700;
  text-decoration: underline;
}








  